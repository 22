//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-192:_7752,_552,_820,_4464,_9996,_8460,_2991,_9992;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-192')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-192', "_7752,_552,_820,_4464,_9996,_8460,_2991,_9992");
        }
      }catch (ex) {
        console.error(ex);
      }